import React, { useEffect, useState } from "react";
import { Form, Image, Spin } from "antd";
import { RiUser2Line } from "react-icons/ri";
import DynamicForm from "../../component/Form";
import {
    useGetCurrencyQuery,
    useGetUserDetailQuery,
} from "../../slices/getSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useSettingMutation } from "../../slices/settingSlice";
import { saveCurrency } from "../../slices/authSlice";
import images from '../../constant/images';
import { useOutletContext } from "react-router-dom";

const Setting = () => {
    const dispatch = useDispatch()
    const [currency, setCurrency] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const { loading, setLoading } = useOutletContext();
    const [imageurl, setImageUrl] = useState([]);
    const [settingId, setSettingid] = useState([]);
    const [formIntinalValue, setFormIntinalValue] = useState("");
    const [form] = Form.useForm();
    const { Logo } = images;
    const [isSaving, setIsSaving] = useState(false);
    const colors = useSelector((state) => state?.persistedReducer?.colors);

  const fields = [
    {
      component: "select",
      label: "Currency",
      name: "currency",
      placeholder: "Please select the currency",
      suffixIcon: (
        <RiUser2Line style={{ color: colors?.primary || "#F47A3A", fontSize: "25px" }} />
      ),
      options: currency,
      colSpan: 12,
    },
    
  ];
  const userData = useSelector((state) => state.persistedReducer);
  const [setting,{isLoading}] = useSettingMutation();
  const { data: getCurrency } = useGetCurrencyQuery();
  const {
    data: getUserDetail,
    refetch,
  } = useGetUserDetailQuery();
 


    useEffect(() => {
        if (getCurrency && getCurrency.data) {
            const transformedOptions = getCurrency.data.map((item) => ({
                label: `${item.name}${" "}${item.symbol}`,
                value: item.id,
            }));
            setCurrency(transformedOptions);
          
        }
        if (getUserDetail && getUserDetail.data) {
            const res = getUserDetail.data.setting;

            setSettingid(res?.id);
            setImageUrl(res?.logo);
            const updatedUserProfileData = {
                currency: res?.currency_id,
                logo: `https://shackbox-api.aleaspeaks.com/${res?.logo}`
            };
            dispatch(saveCurrency(getUserDetail?.data?.setting))
            setFormIntinalValue(updatedUserProfileData);
            form.setFieldsValue(updatedUserProfileData);
            setLoading(false)
        }
    }, [getCurrency, getUserDetail]);

    const onFinish = async (values) => {
        setIsSaving(true)
        try {
            let data = new FormData();

            data.append("logo", values?.logo?.originFileObj);
            data.append("currency", values?.currency);


            const response = await setting({ data, id: settingId });
            if (response.data) {
                refetch();
                toast.success("Setting has been updated successfully");
            } else {
                setErrorMessage(response.error.data.message);
                setTimeout(() => {
                    setErrorMessage('');
                }, 5000)
            }
        } catch (error) {
          toast.error("An error occurred. Please try again.");
        } finally {
            setIsSaving(false)
        }

    };

   
  return (
    <>
    {loading?
        <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(255, 255, 255, 1)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>:
    <>
    
      {errorMessage && <div style={{color: "red"}}>{errorMessage}</div>}
      <DynamicForm
        form={form}
        fields={fields}
        onFinish={onFinish}
        buttonName={"Save"}
        FormInitialValues={formIntinalValue}
        ButtonDisable={isSaving}
        loading={isLoading}
      />
    </>
}</>
  );
};

export default Setting;
