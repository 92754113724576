import React, { useEffect, useState } from "react";
import Title from "../../component/PageTitle";
import { Button, DatePicker, Empty, Select, Space, Spin, Table } from "antd";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useOutletContext } from "react-router-dom";
import { useGetPropertiesQuery, useReportAnalysisQuery } from "../../slices/getSlice";
import { useSelector } from "react-redux";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import * as XLSX from 'xlsx';

Exporting(Highcharts);
ExportData(Highcharts);

export default function ReportAnalysis() {
  const pageSize = 5
  const [currentPage, setCurrentPage] = useState(1);
  const { RangePicker } = DatePicker;
  const [serviceData, setServiceData] = useState([]);
  const [providerTypeData, setProviderTypeData] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState([])
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [properties, setProperties] = useState()
  const [serviceDataMessage, setServiceDataMessage] = useState("");
  const [providerTypeDataMessage, setProviderTypeDataMessage] = useState("");
  const[type,setType] = useState()
  const { loading, setLoading } = useOutletContext();
  const currencyGlobal = useSelector(
    (state) => state?.persistedReducer?.currency?.currency_symbol
  );
  const { data: reportAnalysis, } = useReportAnalysisQuery({
    property_id: selectedProperty.map((item)=>item.value),
    start_date: startDate,
    end_date: endDate,
type:type
  })
  const {
    data: getProperties,
  } = useGetPropertiesQuery();

  useEffect(() => {
    setLoading(false);
    if (reportAnalysis?.providerTypes || reportAnalysis?.services) {
      const { providerTypes = [], services = [] } = reportAnalysis;
      const copiedServices = services.map(service => ({ ...service }));
      const copiedProviderTypes = providerTypes.map(provider => ({ ...provider }));

  

      if (copiedServices.length === 0) {
        setServiceDataMessage("There is no data available");
      } else {
        setServiceData(copiedServices);
        setServiceDataMessage("")
      }

      if (copiedProviderTypes.length === 0) {
        setProviderTypeDataMessage("There is no data available");
      } else {
        setProviderTypeData(copiedProviderTypes);
        setProviderTypeDataMessage("")
      }
      
    }else{
      if(type && reportAnalysis ){
        const dataType = type == 'services'?'Service Name':'Provider Name'
        downloadCSV(dataType)
      }
    }
  
  }, [reportAnalysis,type]);



  useEffect(() => {
    if (getProperties && getProperties?.data) {
      const transformedOptions = getProperties?.data?.map((item) => ({
        label: item.name_of_property,
        value: item.id,
      }));

      setProperties(transformedOptions);
    }

  }, [getProperties]);
  const downloadCSV = (dataType) => {
    // Convert JSON to CSV
 
    setType('')
    const headers = [dataType, "Property Name", "Total Amount", "Start Date", "End Date"];
    const rows =reportAnalysis? reportAnalysis?.map(item => [
      item.name,
      `"${item.property_name}"`,
      item.total_amount,
      item.start_date,
      item.end_date
    ]):''
  
    // Create a CSV string
    const csvContent =
      "data:text/csv;charset=utf-8," +
      [headers.join(","), ...rows.map(row => row.join(","))].join("\n");
  
    // Create a download link
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "report.csv");
  
    // Append link to the DOM and trigger click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
  };
  

const dataAnalysisService = {
  chart: {
    type: 'pie',
  },
  title: {
    text: 'Service Analysis',
  },
  tooltip: {
    pointFormatter: function () {
      return `<b>${this.name}: ${currencyGlobal ?? '$'}${this.y}</b>`;
    },
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          {
            text: 'Download CSV',
            onclick: function () {
           setType('services')   
            },
          },
         
        ],
      },
    },
  },
  series: [
    {
      colorByPoint: true,
      data: serviceData,
    },
  ],
  credits: {
    enabled: false,
  },
};


  const dataAnalysisProvider = {
    chart: {
      type: 'pie',
    },
    title: {
      text: 'Provider Analysis',
    },
    tooltip: {
      pointFormatter: function () {
        return `<b>${this.name}: ${currencyGlobal ?? '$'}${this.y}</b>`;
      },
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          useHTML: true,
          formatter: function () {
            return `
  <div style="text-align: center;">
    <span>${this.point.name}</span><br>
    <span style="color: gray; font-size: 0.8rem;">${currencyGlobal ?? '$'}${this.point.y}</span>
  </div>`;
          },
          distance: 20,
          style: {
            fontSize: '0.875rem',
            textOutline: 'none',
          },
        },
      },
    },
    series: [
      {
        colorByPoint: true,
        data: providerTypeData,
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            {
              text: 'Download CSV',
              onclick: function () {
             setType('providers')        
              },
            },
           
          ],
        },
      },
    },
  
    credits: {
      enabled: false,
    },
  };

 
  


  const handlePropertyChange = (value) => {
    setSelectedProperty(value);

  };
  const providerColumns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      render: (_, record, index) =>
        ((currentPage - 1) * pageSize + index + 1)

    },
    {
      title: "Provider Name",
      dataIndex: "name",
    },

    {
      title: "Amount",
      dataIndex: "y",
      render: (y) => `${currencyGlobal}${y}`,
    },

  ];
  const serviceColumns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      render: (_, record, index) =>
        ((currentPage - 1) * pageSize + index + 1)

    },
    {
      title: "Service Name",
      dataIndex: "name",
    },

    {
      title: "Amount",
      dataIndex: "y",
      render: (y) => `${currencyGlobal}${y}`,
    },

  ];
  console.log(selectedProperty.map((item)=>item.value),'selecteddd')
  return (

    <>
      {
        loading ?
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 1)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div> :
          <>
            <div className="mainWrapper">
              <div className="card chartCard mb-4">
                <div className="card-body">
                  <div className="headerRow">
                    <Title title="Report Analysis" id='report_analysis' />
                    <div className="filterSection gap-1">
                      <Space direction="vertical" size={10} className="me-3">
                        <RangePicker
                          format="YYYY-MM-DD"
                          onChange={(dates, dateStrings) => {
                            if (dateStrings) {
                              setStartDate(dateStrings[0] || null);
                              setEndDate(dateStrings[1] || null);
                            } else {
                              setStartDate(null);
                              setEndDate(null);
                            }
                          }}
                        />
                      </Space>
                      <Select
                      labelInValue
                        mode="multiple"
                        value={selectedProperty}
                        placeholder="Filter by Properties"
                        style={{
                          width: 200,
                          marginRight: "1rem",
                          color: 'black'
                        }}
                        onChange={(value) => handlePropertyChange(value)}
                        options={properties}
                        className="mobWidth"

                      />

                    </div>
                  </div>
                </div>
              </div>
              {providerTypeDataMessage && serviceDataMessage ? <Empty /> : <div className="row">
                <div className="col-md-6">
                  <div className="card chartCard mb-4">
                    <div className="card-body">
                      {providerTypeDataMessage ? (
                        <p>{providerTypeDataMessage}</p>
                      ) : (
                        <HighchartsReact highcharts={Highcharts} options={dataAnalysisProvider} />
                      )}

                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card chartCard mb-4">
                    <div className="card-body">
                      {serviceDataMessage ? (
                        <p>{serviceDataMessage}</p>
                      ) : (
                        <HighchartsReact highcharts={Highcharts} options={dataAnalysisService} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="card chartCard mb-4">
                    <div className="card-header">
                      <h5 className="card-title mb-0 px-4">
                        Provider Data
                      </h5>
                    </div>
                    <div className="card-body">
                      {!providerTypeDataMessage ?
                        <Table
                          rowKey={record => record.id}
                          bordered
                          dataSource={providerTypeData}
                          columns={providerColumns}
                          scroll={{ x: '100%' }}
                          pagination={{
                            pageSize,
                            current: currentPage,
                            onChange: page => setCurrentPage(page),
                          }}
                        /> :
                        <Empty />}

                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="card chartCard mb-4">
                    <div className="card-header">
                      <h5 className="card-title mb-0 px-4">
                        Service Data
                      </h5>
                    </div>
                    <div className="card-body">
                      {!serviceDataMessage ? <Table
                        rowKey={record => record.id}
                        bordered
                        dataSource={serviceData}
                        columns={serviceColumns}
                        scroll={{ x: '100%' }}
                        pagination={{
                          pageSize,
                          current: currentPage,
                          onChange: page => setCurrentPage(page),
                        }}
                      /> : <Empty />}

                    </div>
                  </div>
                </div>
              </div>}
           
            </div>
          </>
      }
    </>
  );
}
