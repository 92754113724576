import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Space,
  Upload,
} from "antd";
import { RiContactsLine } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { HiOutlineTrash } from "react-icons/hi";
const { Item: FormItem } = Form;

const DynamicForm = ({
  form,
  fields,
  onFinish,
  buttonName,
  onUtilityTypeChange,
  anotherButton,
  saveAndExit,
  loading,
  dynamicfields,
  formType,
  ButtonDisable,
  handleDelete,
  isDeleteSave,
  FormInitialValues = {},
}) => {
  const [formValues, setFormValues] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const location = useLocation()
  const colors = useSelector((state) => state?.persistedReducer?.colors);

  const handleDropdownVisibleChange = (value) => {
    if (value) {
      setDropdownVisible(true);
    } else {
      setDropdownVisible(false);
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues?.provider_id) {
      if (onUtilityTypeChange) {
        onUtilityTypeChange(changedValues?.provider_id);
      }
    }
    if (allValues) {
      setFormValues(allValues);
    }
  };

  useEffect(() => {
    if (FormInitialValues?.company_id == null) {
      form.resetFields(["company_id"]);
    }
    if (FormInitialValues?.state_id == null) {
      form.resetFields(["state_id"]);
      form.resetFields(["city_id"]);
    }
  }, [FormInitialValues]);

  const handleFinish = (allValues) => {
    const staticValues = {};
    const dynamicValues = {};
    if (dynamicfields) {
      for (const field of allFields) {
        const value = formValues[field?.name];
        if (field?.static) {
          staticValues[field?.name] = value;
        } else {
          dynamicValues[field?.name] =
            value !== undefined ? value : field?.defaultValue;
        }
      }
      onFinish(staticValues, dynamicValues, formType);
     
    } else {
      setFormValues(allValues);
      onFinish(formValues,dynamicValues, formType);
     
    }
  };

 
  const allFields = dynamicfields ? [...fields, ...dynamicfields] : [...fields];

  const onDelete = (fieldId) => {
    handleDelete(fieldId);
  };
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      onValuesChange={handleValuesChange}
      initialValues={FormInitialValues ? FormInitialValues : formValues}
    >
      <Row gutter={16}>
        {allFields.map((field, index) => {
          const isHidden = field?.hidden
            ? typeof field?.hidden === "function"
              ? field?.hidden()
              : field?.hidden
            : "";

          const shouldDelete =
            location.pathname === "/property_details" && !field?.static && formType == "add" ;
          return (
            !isHidden && (
              <Col
                key={field?.name}
                md={field?.colSpan || 24}
                offset={field?.offset || 0}
              >
                {(field?.component === "input" || !field?.component) &&
                
               
                      <>
                        <FormItem
                          label={field?.label}
                          name={field?.name}
                          rules={field?.rules}
                          dependencies={
                            field?.dependencies
                              ? [field?.dependencies]
                              : undefined
                          }
                        >
                          {field?.type === "password" ? (
                            <Input.Password
                              placeholder={field?.placeholder}
                              defaultValue={field?.defaultValue}
                              className="form-control"
                              prefix={field?.prefix}
                              disabled={field?.disabled || ""}
                              type={field?.type}
                            />
                          ) : (
                            <Input
                              placeholder={field?.placeholder}
                              className="form-control"
                              defaultValue={field?.defaultValue}
                              disabled={field?.disabled || ""}
                              prefix={
                                field?.prefix ? (
                                  field?.prefix
                                ) : (
                                  <RiContactsLine
                                    style={{
                                      color: colors?.primary || "#F47A3A",
                                      fontSize: "25px",
                                    }}
                                  />
                                )
                              }
                              type={field?.type}
                            />
                          )}
                        </FormItem>
                        {shouldDelete?  (
                          <span className="deleteIcon">
                            <HiOutlineTrash
                              style={{  cursor: isDeleteSave ? "not-allowed" : "pointer", fontSize: "20px" }}
                              onClick={!isDeleteSave ? () => {
                                onDelete(field?.id);
                                // form.resetFields([field?.name]); // Remove the field from form validation
                            } : null}
                            />
                          </span>
                        ):''}
                      </>

                  }
                {field?.component === "select" && (
                  <FormItem
                    label={field?.label}
                    name={field?.name}
                    rules={field?.rules}
                  >
                    <Select
                      id={index}
                      dropdownRender={(originNode) => (
                        <div
                          style={{
                            padding: "0px",
                            display:
                              field?.name === "category_id" ||
                                field?.name === "frequency" ||
                                field?.name === "provider_id" ||
                                field?.name === "company_id"
                                ? "block"
                                : dropdownVisible
                                  ? "block"
                                  : "none", // Initially hidden
                          }}
                        >
                          {originNode}
                        </div>
                      )}
                      className="formControl select-custom "
                      disabled={field.disabled || ""}
                      showSearch
                      onSelect={() => setDropdownVisible(false)}
                      onSearch={handleDropdownVisibleChange}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      mode={field.mode || ""}
                      optionLabelProp="label"
                      placeholder={field?.placeholder}
                      options={field?.options}
                      suffixIcon={field?.suffixIcon}
                      onChange={(value) => {
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          [field?.name]: value,
                        }));
                        if (field?.onChange) {
                          field?.onChange(value);
                        }
                        if (
                          field?.name === "provider_id" &&
                          onUtilityTypeChange
                        ) {
                          onUtilityTypeChange(value);
                        }
                      }}
                      optionRender={(option) => (
                        <Space>
                          <span role="img" aria-label={option.data.value}>
                            {option.data.label}
                          </span>
                        </Space>
                      )}
                    />
                  </FormItem>
                )}
                {field?.component === "checkbox" && (
                  <Row justify="space-between">
                    <Col span={12}>
                      <FormItem name={field?.name} valuePropName="checked">
                        <Checkbox
                          className="rememberMe"
                          onChange={(value) => {
                            setFormValues((prevValues) => ({
                              ...prevValues,
                              [field.name]: value,
                            }));
                            if (field?.onChange) {
                              field?.onChange(value);
                            }
                          }}
                        >
                          {field?.label}
                        </Checkbox>
                      </FormItem>
                    </Col>
                    {buttonName === "Sign In" && (
                      <Col span={12} className="forgotpassword">
                        <Link
                          to="/forgot-password"
                          style={{ lineHeight: "32px" }}
                          className="forgotLink"
                        >
                          Forgot Password?
                        </Link>
                      </Col>
                    )}
                  </Row>
                )}
              </Col>
            )
          );
        })}
      </Row>
      <Row className="formButtons">
        <Col md={24} className="text-center mt-3">
          {anotherButton && <>{anotherButton}</>}
          <Button
            type="primary"
            htmlType="submit"
            className={anotherButton ? "btnFilled ms-3 w-150" : "authBtn"}
            disabled={loading ? loading :  ButtonDisable}
          >
            {loading ? "Loading..." : buttonName}
          </Button>
          {saveAndExit && <>{saveAndExit}</>}
      
        </Col>
      </Row>
    </Form>
  );
};

export default DynamicForm;
