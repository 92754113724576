import React, { useEffect, useState } from "react";
import Title from "../../component/PageTitle";
import { Button, Table, Row, Col, Spin } from "antd";
import { TbRefresh } from "react-icons/tb";
import { MdOutlineAddHomeWork } from "react-icons/md";
import { TbUser } from "react-icons/tb";
import { GrBusinessService } from "react-icons/gr";
import { GrTransaction } from "react-icons/gr";
import { useDispatch } from "react-redux";
import * as expenseService from "../../services/expensesServices";
import { LoadingOutlined } from "@ant-design/icons";
import { useOutletContext } from "react-router-dom";
import { useCategorizePlaidQuery } from "../../slices/getSlice";

export default function ExpenseCategory() {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const { loading, setLoading } = useOutletContext();
    const { data: categorizePlaidData } = useCategorizePlaidQuery()
    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };


    const [transactionData, setTransactionData] = useState([]);
    const [categorizedTransactionData, setCategorizedTransactionData] = useState(
        []
    );

    useEffect(() => {
        if (categorizePlaidData) {
            setCategorizedTransactionData(categorizePlaidData);
            setLoading(false)
        }
    }, [categorizePlaidData])

    function formatTransactionName(str) {
        let formattedStr = str.replace(/_/g, " ");

        formattedStr = formattedStr.replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });

        return formattedStr;
    }

    const columns = [
        {
            title: "Provider Type",
            dataIndex: "providerType",
            render: (text, record, index) => (
                <>
                    <div className="serial-no-container">
                        <p className="serial-no">
                            {formatTransactionName(record?.providerType)}
                        </p>
                    </div>
                </>
            ),
        },
        {
            title: "Transaction Name",
            dataIndex: "transactionName",
            render: (text, record, index) => (
                <>
                    <div className="category-data-container">
                        <p className="category-data-text">{record?.transaction_name}</p>
                    </div>
                </>
            ),
        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (text, record, index) => (
                <>
                    <div className="amount-data-container">
                        <p className="amount-data-text">${(record?.price ? record?.price : record?.amount)?.toFixed(2)}</p>
                    </div>
                </>
            ),
        },
        {
            title: "Transiction Date",
            dataIndex: "transactionDate",
            render: (text, record, index) => (
                <>
                    <div className="date-data-container">
                        <p className="date-data-text">{DateFormatter(record?.date)}</p>
                    </div>
                </>
            ),
        },
    ];
    const flatData = [];
    Object.keys(categorizedTransactionData)?.forEach((providerType) => {
        categorizedTransactionData[providerType]?.forEach((transaction) => {
            flatData?.push({
                providerType,
                ...transaction,
            });
        });
    });

    let total = 0;

    for (let category in categorizedTransactionData) {
        categorizedTransactionData[category]?.forEach((transaction) => {
            total += transaction?.price ? transaction?.price : transaction?.amount;
        });
    }



    return (
        <>
            {loading ?
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Spin size="large" />
                </div> :
                <>
                    <div className="mainWrapper">
                        <div className="card titleCard mb-4">
                            <div className="card-body d-flex justify-content-between align-items-center">
                                <Title title="View Expense" />
                            </div>
                        </div>
                    </div>

                    <div className="all-expenses-container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="card chartCard mb-4">
                                    <div className="card-body">
                                        <div className="topBox">
                                            <span className="dashIcon">
                                                <MdOutlineAddHomeWork />
                                            </span>
                                            <div className="dashText">
                                                <p>Utility Provider</p>

                                                <h4>{categorizedTransactionData?.Utility_Provider?.length || 0}</h4>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="card chartCard mb-4">
                                    <div className="card-body">
                                        <div className="topBox">
                                            <span className="dashIcon">
                                                <TbUser />
                                            </span>
                                            <div className="dashText">
                                                <p>Custom Provider</p>

                                                <h4>{categorizedTransactionData?.Custom_Provider?.length || 0}</h4>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="card chartCard mb-4">
                                    <div className="card-body">
                                        <div className="topBox">
                                            <span className="dashIcon">
                                                <TbUser />
                                            </span>
                                            <div className="dashText">
                                                <p>Service Provider</p>

                                                <h4>{categorizedTransactionData?.Service_Provider?.length || 0}</h4>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="card chartCard mb-4">
                                    <div className="card-body">
                                        <div className="topBox">
                                            <span className="dashIcon">
                                                <GrTransaction />
                                            </span>
                                            <div className="dashText">
                                                <p>Transactions Amount</p>
                                                <h4>{
                                                    `$${total !== undefined && total !== null
                                                        ? total.toFixed(2)
                                                        : "0.00"
                                                    }`}</h4>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-4">
                                <div className="card chartCard h-100">
                                    <div className="card-header">
                                        <h5 className="card-title">Expenses Table</h5>
                                    </div>
                                    <div className="card-body">
                                        <Table
                                            pagination={{ pageSize: "5" }}
                                            columns={columns}
                                            dataSource={flatData}
                                            rowKey={(record) =>
                                                `${record.providerType}-${record?.transaction_name
                                                    ? record?.transaction_name
                                                    : record?.name
                                                }`
                                            }
                                            scroll={{ x: 'auto' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}
