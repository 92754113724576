import React, { useEffect, useRef, useState } from "react";
import ChartRevenue from "../../HelperCharts/RevenueChart";
import Title from "../../component/PageTitle";
import { BsBuildings } from "react-icons/bs";
import { GiReceiveMoney, GiExpense } from "react-icons/gi";
import { Table, Select, Spin, Button, Modal, Form, Input, message } from "antd";
import ChartProfit from "../../HelperCharts/ProfitChart";
import { useDispatch, useSelector } from "react-redux";
import {
    useUpdateLabelsMutation,
} from "../../slices/apiSlice";
import { LuRefreshCcw } from "react-icons/lu";
import { useGetPropertiesQuery, useGetUserDetailQuery, useGetLabelsQuery, useGetTopRevenuePropertiesQuery, useGetTopProfitablePropertiesQuery, useGetStatisticsQuery, useLazyGetStatisticsQuery, useLazyGetTopRevenuePropertiesQuery, useLazyGetTopProfitablePropertiesQuery } from "../../slices/getSlice";
import { useOutletContext } from "react-router-dom";

const Dashboard = () => {
    const currencyGlobal = useSelector(
        (state) => state?.persistedReducer?.currency?.currency_symbol
    );

    const { loading, setLoading } = useOutletContext();
    const {
        data: getUserDetail,
    } = useGetUserDetailQuery();
    const defaultCurrency = getUserDetail?.data.setting.currency_symbol;
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const dispatch = useDispatch()
    // let navigate = useNavigate()
    const currency = currencyGlobal ? currencyGlobal : defaultCurrency;
    const [showNewSelect, setShowNewSelect] = useState(false);
    const [properties, setProperties] = useState()
    const [newSelectOptions, setNewSelectOptions] = useState([]);
    const [filterValue, setFilterValue] = useState()
    const [selectedValue, setSelectedValue] = useState();
    const [selectedProperty, setSelectedProperty] = useState([])
    const [statistics, setStatistics] = useState();
    const [revenueProperty, setRevenueProperty] = useState("");
    const [profitProperty, setProfitProperty] = useState();
    const [isEditModal, setIsEditModal] = useState(false);
    const [labelForm] = Form.useForm();
    const [isSaving, setIsSaving] = useState(false);
    const [labelUpdate, { isLoading: isLoadingUpdate }] = useUpdateLabelsMutation();

    const [data, setData] = useState({
        filter_type: "",
        filter_value: "",
        property_ids: " "
    });

    const {
        data: labelData,
        error: labelsError,
        isLoading: loadingData,
        isSuccess: islablesuccess,
        refetch,
    } = useGetLabelsQuery('dashboard_labels');

    const [fetchStatistics, { data: statisticsData, isLoading: isStatisticsLoading, isError: isStatisticsError, refetch: refetchStatiscs }] = useLazyGetStatisticsQuery();
    const [fetchTopRevenueProperties, { data: topRevenuePropertiesData }] = useLazyGetTopRevenuePropertiesQuery();
    const [fetchTopProfitableProperties, { data: topProfitablePropertiesData,isSuccess:isSuccessapi }] = useLazyGetTopProfitablePropertiesQuery();

    const {
        data: getProperties,
    } = useGetPropertiesQuery();


   

   
  
    useEffect(() => {
        if (statisticsData) {
            setStatistics(statisticsData)
        }
        if (topRevenuePropertiesData) {
            setRevenueProperty(topRevenuePropertiesData)
        }
        if (topProfitablePropertiesData) {
            setProfitProperty(topProfitablePropertiesData)
          
        }
    
    }, [statisticsData, topRevenuePropertiesData, topProfitablePropertiesData])

    useEffect(() => {
        if (getProperties && getProperties?.data) {
            const transformedOptions = getProperties?.data?.map((item) => ({
                label: item.name_of_property,
                value: item.id,
            }));
            setProperties(transformedOptions);
        } else {

        }
    }, [getProperties]);
    const isMounted = useRef(false);
    useEffect(() => {
    if (!isMounted.current) {
        const fetchData = async () => {
            try {
                await fetchStatistics();
                await fetchTopRevenueProperties();
                await fetchTopProfitableProperties();
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
        isMounted.current = true;
    }
        
    }, []);
    function absString(n) {
        let numberString = n.toString();
        if (numberString[0] === '-') {
            return numberString.substring(1);
        }
        else {
            return numberString;
        }
    }

    const netProfit = statistics?.net_profit || 0;
    const numberWithoutCommas = netProfit ? netProfit?.replace(/,/g, '') : '';
    let netProfitNumber = absString(netProfit);

    const columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            key: "serial",
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: "Property Name",
            dataIndex: "name_of_property",
            key: "name",
        },
    ];

    const handleChange = (value) => {
        setSelectedProperty(value);
        handleMonthChange(value)
    };

    const handleDateChange = (value) => {
        setSelectedValue(value);

        switch (value) {
            case "monthly": // Monthly
                setNewSelectOptions([
                    { value: "January", label: "January" },
                    { value: "February", label: "February" },
                    { value: "March", label: "March" },
                    { value: "April", label: "April" },
                    { value: "May", label: "May" },
                    { value: "June", label: "June" },
                    { value: "July", label: "July" },
                    { value: "August", label: "August" },
                    { value: "September", label: "September" },
                    { value: "October", label: "October" },
                    { value: "November", label: "November" },
                    { value: "December", label: "December" },
                ]);
                setShowNewSelect(true);
                break;
            case "quarterly": // Quarterly
                setNewSelectOptions([
                    { value: "Q1", label: "Q1 (Jan-Mar)" },
                    { value: "Q2", label: "Q2 (Apr-Jun)" },
                    { value: "Q3", label: "Q3 (Jul-Sep)" },
                    { value: "Q4", label: "Q4 (Oct-Dec)" },
                ]);
                setShowNewSelect(true);
                break;
            case "halfyearly": // Half Yearly
                setNewSelectOptions([
                    { value: "H1", label: "H1 (Jan-Jun)" },
                    { value: "H2", label: "H2 (Jul-Dec)" },
                ]);
                setShowNewSelect(true);
                break;
            default:
                setShowNewSelect(false);
                break;
        }
    };
  
    const handleMonthChange = async (value) => {
      
        // Update filterValue if value is a string
        if (typeof value === 'string') {
            setFilterValue(value);
        }

        // Define the values object
        const values = {
            filter_type: selectedValue || '',
            filter_value: typeof value === 'string' ? value : filterValue,
            property_ids: typeof value === 'string' ? selectedProperty : value
        };

        setData(values);

        // Trigger the getStatistics query with the new parameters
        const res = await fetchStatistics({
            filter_type: values.filter_type,
            filter_value: values.filter_value,
            property_ids: values.property_ids
        });

        if (res.data) {
            setStatistics(res.data);
        }

        // Trigger the getTopRevenueProperties query with the new parameters
        const getRevenue = await fetchTopRevenueProperties({
            filter_type: values.filter_type,
            filter_value: values.filter_value,
            property_ids: values.property_ids
        });

        if (getRevenue?.data) {
            setRevenueProperty(getRevenue.data);
        }

        // Trigger the getTopProfitableProperties query with the new parameters
        const profitProperty = await fetchTopProfitableProperties({
            filter_type: values.filter_type,
            filter_value: values.filter_value,
            property_ids: values.property_ids
        });

        if (profitProperty?.data) {
            setProfitProperty(profitProperty.data);
           
        }
      
    };


    const openEditModal = () => {
        setIsEditModal(true);
    }

    const handleCancel = () => {
        setIsEditModal(false);
    }

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };

    const updateLabels = async (value) => {
        const convertedData = {
            layout_data: {
                Top_5_profitable_chart: value.Top_5_profitable_chart !== undefined ? value.Top_5_profitable_chart : labelData.Top_5_profitable_chart,
                Top_5_profitable_table: value.Top_5_profitable_table !== undefined ? value.Top_5_profitable_table : labelData.Top_5_profitable_table,
                revenue_growth_comparison: value.revenue_growth_comparison !== undefined ? value.revenue_growth_comparison : labelData.revenue_growth_comparison,
                top_5_revenue: value.top_5_revenue !== undefined ? value.top_5_revenue : labelData.top_5_revenue,
                total_expense: value.total_expense !== undefined ? value.total_expense : labelData.total_expense,
                total_properties: value.total_properties !== undefined ? value.total_properties : labelData.total_properties,
                total_revenue: value.total_revenue !== undefined ? value.total_revenue : labelData.total_revenue,
            }
        };
        setIsSaving(true);
        try {          
            await labelUpdate({ label: 'dashboard_labels', value: convertedData }).unwrap();
            message.success('Labels updated successfully!'); // Add this line
            refetch();
        }
        catch {
        } finally {
            setIsSaving(false);
            setIsEditModal(false);
            labelForm.resetFields();
        }
    }


    return (

        <>
        {loading?
             <div
             style={{
               position: "absolute",
               top: 0,
               left: 0,
               right: 0,
               bottom: 0,
               backgroundColor: "rgba(255, 255, 255, 1)",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
             }}
           >
             <Spin size="large" />
           </div>:
           <>
            <div className="mainWrapper">
                <div className="card chartCard mb-4">
                    <div className="card-body">
                        <div className="headerRow">
                            <Title title="Dashboard" id='dashboard' />
                            <div className="filterSection gap-1">
                                <Button onClick={openEditModal} className="editLabelBtnWrapper editLabelButton mobWidth">Edit Labels</Button>
                                <Select
                                    className="mobWidth customMultiSelect"
                                    mode="multiple"
                                    value={selectedProperty}
                                    placeholder="Filter by Properties"
                                    style={{
                                        width: 300,
                                        marginRight: "1rem",
                                    }}
                                    onChange={(value) => handleChange(value)}
                                    options={properties}
                                />

                                <Select
                                    placeholder="Filter by Date"
                                    value={selectedValue}
                                    style={{ width: 150 }}
                                    onChange={handleDateChange}
                                    options={[
                                        { value: "monthly", label: "Monthly" },
                                        { value: "quarterly", label: "Quarterly" },
                                        { value: "halfyearly", label: "Half Yearly" },
                                        // { value: "custom", label: "Custom" },
                                    ]}
                                    className=""
                                />
                                {showNewSelect && (
                                    <Select
                                        style={{ width: 150, marginLeft: 10 }}
                                        placeholder="Select Here"
                                        onChange={(value) => handleMonthChange(value)}
                                        options={newSelectOptions}
                                    />
                                )}
                                <LuRefreshCcw
                                    className="customRefresh-icon"
                                    onClick={() => {
                                        
                                        fetchStatistics()
                                        fetchTopProfitableProperties()
                                        fetchTopRevenueProperties()
                                     setFilterValue('')
                                        setNewSelectOptions('')
                                     setSelectedProperty([])
                                     setSelectedValue('')
                                        setData('')
                                        setShowNewSelect(false)

                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chartWrapper">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="card chartCard mb-4">
                                <div className="card-body">
                                    <div className="topBox">
                                        <span className="dashIcon" style={{ background: colors?.primary }}>
                                            <BsBuildings />
                                        </span>
                                        <div className="dashText">
                                            <p>{labelData?.total_properties || 'Total Properties'}</p>
                                            {isStatisticsError && <div>Error loading statistics.</div>}
                                            {!isStatisticsLoading && !isStatisticsError && (
                                                <>
                                                    <h4>{statistics?.total_properties}</h4>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="card chartCard mb-4">
                                <div className="card-body">
                                    <div className="topBox">
                                        <span className="dashIcon" style={{ background: colors?.primary }}>
                                            <GiReceiveMoney />
                                        </span>
                                        <div className="dashText" >
                                            <p>{labelData?.total_revenue || 'Total Revenue'}</p>
                                            {isStatisticsError && <div>Error loading statistics.</div>}
                                            {!isStatisticsLoading && !isStatisticsError && (
                                                <>
                                                    <h4>{`${currency}${statistics?.total_revenue}`}</h4>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="card chartCard mb-4">
                                <div className="card-body">
                                    <div className="topBox">
                                        <span className="dashIcon" style={{ background: colors?.primary }}>
                                            <GiExpense />
                                        </span>
                                        <div className="dashText">
                                            <p>{labelData?.total_expense || 'Total Expense'}</p>
                                            {isStatisticsError && <div>Error loading statistics.</div>}
                                            {!isStatisticsLoading && !isStatisticsError && (
                                                <>
                                                    <h4>{`${currency}${statistics?.total_expense}`}</h4>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div
                                className={
                                    numberWithoutCommas > 0
                                        ? "card chartCard netProfiteCard"
                                        : "card chartCard netLossCard"
                                }
                            >
                                <div className="card-body">
                                    {isStatisticsError && <div>Error loading statistics.</div>}
                                    {!isStatisticsLoading && !isStatisticsError && (
                                        <>
                                            <h2>
                                                {numberWithoutCommas > 0
                                                    ? `Net Profit: ${currency}${netProfit}`
                                                    : `Net Loss:  ${currency}${netProfitNumber}`}
                                            </h2>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-5 mb-4">
                            <div className="card chartCard h-100">
                                <div className="card-header">
                                    <h5 className="card-title">
                                        {labelData?.top_5_revenue || 'Top 5 Revenue Generating Properties'}
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <Table
                                        rowKey={(record) => record.id}
                                        bordered
                                        pagination={false}
                                        dataSource={revenueProperty?.topProperties}
                                        columns={columns}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 mb-4">
                            <div className="card chartCard h-100">
                                <div className="card-body">
                                    <ChartRevenue data={data} label={labelData?.revenue_growth_comparison} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7 mb-4">
                            <div className="card chartCard h-100">
                                <div className="card-body">
                                    <ChartProfit data={data} label={labelData?.Top_5_profitable_chart} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 mb-4">
                            <div className="card chartCard h-100">
                                <div className="card-header">
                                    <h5 className="card-title">{labelData?.Top_5_profitable_table || 'Top 5 Profitable Properties'}</h5>
                                </div>
                                <div className="card-body">
                                    <Table
                                        rowKey={(record) => record.id}
                                        bordered
                                        pagination={false}
                                        dataSource={profitProperty?.topProperties}
                                        columns={columns}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           
            <Modal
                title="Change Form Labels"
                open={isEditModal}
                onCancel={handleCancel}
                className="assign_model"
                width={700}
                footer={false}
                form={labelForm}
            >
                <div className="modalBody">
                    <Form
                        name="labelForm"
                        className="modalForm"
                        layout="vertical"
                        onFinish={updateLabels}
                        form={labelForm}
                    >
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Item
                                    name="total_properties"
                                    label={capitalizeFirstLetter(labelData?.total_properties) || "Total Properties"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="total_expense"
                                    label={capitalizeFirstLetter(labelData?.total_expense) || "Total Expense"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="total_revenue"
                                    label={capitalizeFirstLetter(labelData?.total_revenue) || "Total Revenue"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="top_5_revenue"
                                    label={capitalizeFirstLetter(labelData?.top_5_revenue) || "Top 5 Revenue Generating Properties"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="revenue_growth_comparison"
                                    label={capitalizeFirstLetter(labelData?.revenue_growth_comparison) || "Revenue Growth Comparison for Properties (Up to the last 3 Months)"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="Top_5_profitable_table"
                                    label={capitalizeFirstLetter(labelData?.Top_5_profitable_table) || "Top 5 Profitable Properties"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="Top_5_profitable_chart"
                                    label={capitalizeFirstLetter(labelData?.Top_5_profitable_chart) || "Top 5 Profitable Properties"}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-12 text-center mt-4">
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="authBtn" disabled={isSaving}>
                                        {isLoadingUpdate ? "Loading.." : "Save"}
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal></>
           
            }
        </>


    )
}
export default Dashboard;
