import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Modal, Select, Table, Tooltip, Input, Row, Col, Spin } from "antd";
import Title from "../../component/PageTitle";
import { IoAddCircleOutline } from "react-icons/io5";
import {
    useCategorizePlaidDataQuery,
    useGetCategoryQuery,
    useGetPropertiesQuery,
    useGetUtilityCategoryQuery,
} from "../../slices/getSlice";
import { DatePicker, Space } from "antd";
import { useAddPropertyProviderMutation, useAddTransactionMutation } from "../../slices/apiSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { useOutletContext } from "react-router-dom";

function AllTranscation() {
    const [form] = Form.useForm();
    const { loading, setLoading } = useOutletContext();
    const { RangePicker } = DatePicker;
    const { data: categorizePlaidData, refetch, isLoading: isLoadingPlaid } = useCategorizePlaidDataQuery();
    const { data: getProperties } = useGetPropertiesQuery();
    const { data: getUtilityCategory } = useGetUtilityCategoryQuery();
    const [addPropertyProvider, { isLoading: isaddLoading }] = useAddPropertyProviderMutation();
    const { data: getCategory } = useGetCategoryQuery("expense");
    const [addTransaction] = useAddTransactionMutation();
    const today = new Date();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRowsData, setSelectedRowsData] = useState([])
    const [properties, setProperties] = useState([]);
    const [category, setCategory] = useState();
    const [linkData, setLinkData] = useState();
    const [formData, setFormData] = useState([]);
    const [utilityCategory, setUtilityCategory] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const colors = useSelector((state) => state?.persistedReducer?.colors);
    const [isSplitPayment, setIsSplitPayment] = useState(false);
    const [propertyAmounts, setPropertyAmounts] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;
    const [isSaving, setIsSaving] = useState(false)
    const start_date = startDate
        ? new Date(startDate)
        : new Date(today.getFullYear(), today.getMonth(), 1);
    const end_date = endDate
        ? new Date(endDate)
        : new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const dataSource = categorizePlaidData?.data
        ?.map((transaction) => {

            const parsedData = JSON.parse(transaction.json_data);

            const filteredItems = [parsedData].filter((item) => {
                const itemDate = new Date(item.date);
                return itemDate >= start_date && itemDate <= end_date;
            });

            if (filteredItems.length > 0) {
                return {
                    key: filteredItems[0]?.transaction_id,
                    amount: filteredItems[0]?.amount,
                    date: filteredItems[0]?.date,
                    name: filteredItems[0]?.name,
                    transaction_id: transaction?.id,
                    property_id: null,
                    provider_type_id: null,
                    category_id: null,
                };
            }

            return null;
        })
        .filter((item) => item !== null);

    const DateFormatter = (date) => {
        const dateObj = new Date(date);
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();
        return `${month} ${day} ${year}`;
    };

    useEffect(() => {
        if (getProperties && getProperties?.data) {
            const transformedOptions = getProperties?.data?.map((item) => ({
                label: item.name_of_property,
                value: item.id,
            }));
            setLoading(false)
            setProperties(transformedOptions);
        }
        if (getUtilityCategory && getUtilityCategory?.data) {
            const transformedOptions = getUtilityCategory?.data?.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setUtilityCategory(transformedOptions);
        }


        if (getCategory && getCategory.data) {
            if (getCategory && getCategory.data) {
                const transformedOptions = getCategory.data
                    .filter((item) => item.name !== 'Recurring')
                    .map((item) => ({
                        label: item.name,
                        value: item.id,
                    }));
                setCategory(transformedOptions);
            }
        }
    }, [getProperties, getUtilityCategory, getCategory]);

    const columns = [
        {
            title: "Transaction Name",
            dataIndex: "transactionName",
            render: (text, record) => (
                <div className="category-data-container">
                    <p className="category-data-text">{record.name}</p>
                </div>
            ),
        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (text, record) =>
                record.amount ? (
                    <div className="amount-data-container">
                        <p className="amount-data-text">${record.amount?.toFixed(2)}</p>
                    </div>
                ) : (
                    ""
                ),
        },
        {
            title: "Transaction Date",
            dataIndex: "transactionDate",
            render: (text, record) =>
                record.date ? (
                    <div className="date-data-container">
                        <p className="date-data-text">{DateFormatter(record.date)}</p>
                    </div>
                ) : (
                    ""
                ),
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => {
                return (
                    <div className="action-container">
                        <Tooltip>
                            <button className="btn btnAdd me-2">
                                <IoAddCircleOutline onClick={() => { setIsModalOpen(true); setLinkData(record) }} />
                            </button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const handleSelect = (record, selected) => {
        if (selected) {
            setSelectedRowKeys((prev) => [...prev, record.key]);
            setSelectedRowsData((prev) => [...prev, record]);
        } else {
            setSelectedRowKeys((prev) => prev.filter((key) => key !== record.key));
            setSelectedRowsData((prev) => prev.filter((row) => row.key !== record.key));
        }
    };

    const toggleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedRowKeys(dataSource.map((r) => r.key));
            setSelectedRowsData(dataSource);
        } else {
            setSelectedRowKeys([]);
            setSelectedRowsData([]);
        }
    };

    const headerCheckbox = (
        <Checkbox
            checked={selectedRowKeys.length === dataSource?.length}
            indeterminate={
                selectedRowKeys.length > 0 &&
                selectedRowKeys.length < dataSource?.length
            }
            onChange={toggleSelectAll}
            className="custom-check"
        />
    );

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
        columnTitle: headerCheckbox,
        onSelect: handleSelect,
        onSelectAll: (selected, selectedRows) => {
            if (selected) {
                setSelectedRowKeys(selectedRows.map((row) => row.key));
                setSelectedRowsData(selectedRows);
            } else {
                setSelectedRowKeys([]);
                setSelectedRowsData([]);
            }
        },
    };


    const handleFinish = async (value) => {
        let storedFormData = [...formData];
        setIsSaving(true)
        try {
            if (isSplitPayment) {
                // Transform propertyAmounts into the desired format
                const propertyAmount = Object.entries(propertyAmounts).map(([key, amount]) => ({
                    property_id: parseInt(key, 10), // Ensure the key is a number
                    amount: parseFloat(amount) || 0, // Convert to float and default to 0 if NaN
                }));

                // Prepare the final values without propertyAmounts
                const finalValues = {
                    ...value,
                    propertyAmount, // Only include the transformed propertyAmount
                };

                delete finalValues.propertyAmounts;
                finalValues.is_split = 'true';
                finalValues.provider_type_id = value.utilityCategory?.value;
                finalValues.category_id = value.category_id?.value;
                finalValues.amount = linkData?.amount;
                finalValues.start_date = linkData?.date;
                finalValues.transaction_id = linkData?.transaction_id;

                // API call
                const response = await addPropertyProvider(finalValues);
                if (response.data) {
                    toast.success("Successfully added");
                    refetch();
                    form.resetFields();
                    setIsModalOpen(false);
                } else {
                    toast.error(`${response?.error?.data?.message}`);
                }
            } else {
                if (linkData) {
                    let values = {
                        property_id: value.properties.value,
                        provider_type_id: value.utilityCategory.value,
                        category_id: value.category_id.value,
                        amount: linkData?.amount,
                        start_date: linkData?.date,
                        transaction_id: linkData?.transaction_id,

                    };
                    storedFormData.push(values);
                    setFormData(storedFormData);

                } else {
                    storedFormData = selectedRowsData.map((item) => ({
                        ...item,
                        property_id: value.properties.value,
                        provider_type_id: value.utilityCategory.value,
                        category_id: value.category_id.value,
                    }));
                }


                const response = await addPropertyProvider({ providers: storedFormData });
                if (response.data) {
                    toast.success("Successfully added");
                    refetch();
                    form.resetFields();
                    setIsModalOpen(false);
                } else {
                    toast.error(`${response?.error?.data?.message}`);
                }
            }
        } catch (error) {
            toast.error("An error occurred. Please try again.");

        } finally {
            setIsSaving(false)
        }
    };


    const handleSplitPaymentChange = (e) => {
        setIsSplitPayment(e.target.checked);
        if (!e.target.checked) {
            setPropertyAmounts({});
        }
    };
    const getTransactions = async () => {
        try {
            const response = await addTransaction();  
            if(response?.data?.data?.length === 0)
            {
                swal({
                    title: "Alert",
                    text: `${response.data.message}`,
                    icon: "warning",
                    dangerMode: true,
                })
            }
            refetch()
        } catch (error) {
            console.log(error, 'error')
        }
    };


    return (
        <>
        {loading?
            <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 1)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>:
        <>
            <div className="mainWrapper">
                <div className="card titleCard mb-4">
                    <div className="card-body">
                        <Title title="All Transactions" id="transaction" />
                        <div className="rightButtons">
                            <Space direction="vertical" size={10} className="me-3">
                                <RangePicker
                                    format="YYYY-MM-DD"
                                    onChange={(dateString) => {
                                        if (dateString) {
                                            setStartDate(dateString[0] || null);
                                            setEndDate(dateString[1] || null);
                                        } else {
                                            setStartDate(null);
                                            setEndDate(null);
                                        }
                                    }}
                                />
                            </Space>
                            {dataSource?.length ? (
                                selectedRowKeys?.length > 1 ||
                                    selectedRowKeys?.length === dataSource?.length ? (
                                    <Button
                                        className="me-3"
                                        type="primary"
                                        style={{
                                            backgroundColor: colors?.primary || "#F47A3A",
                                        }}
                                        onClick={() => setIsModalOpen(true)}
                                    >
                                        {"Add your transaction"}
                                    </Button>
                                ) : (
                                    ""
                                )
                            ) : (
                                ""
                            )}
                            <Button
                                type="primary"
                                style={{
                                    backgroundColor: colors?.primary || "#F47A3A",
                                }}
                                onClick={() => getTransactions()}
                            >
                                {"Add transaction"}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="card tableCard">
                    <div className="card-body">
                        <div className="table-responsives">
                            {isLoadingPlaid ? <Spin style={{ display: "flex", alignItems: "center", justifyContent: "center" }} /> :
                                <Table
                                    rowKey={(record) => record.key}
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={dataSource ? dataSource : ""}
                                    scroll={{ x: '100%' }}
                                    pagination={{
                                        pageSize,
                                        current: currentPage,
                                        onChange: page => setCurrentPage(page),
                                    }}
                                />}
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                className="deleteModal addTransCustomModal"
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={false}
                title={
                    <>
                        Add your transaction
                        <div style={{ marginTop: '8px', fontWeight: 700, width: 'fit-content', fontSize: '1rem' }}>
                            ${linkData?.amount?.toFixed(2)}
                        </div>
                    </>
                }>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => {
                        const totalSplitAmount = Object.values(propertyAmounts)
                            .reduce((sum, amount) => sum + (parseFloat(amount) || 0), 0)
                            .toFixed(2);

                        // Round the actual amount to two decimal places
                        const actualAmount = linkData?.amount?.toFixed(2);

                        // Compare both rounded values
                        if (isSplitPayment && totalSplitAmount !== actualAmount) {
                            toast.error(`The total split payment ($${totalSplitAmount}) must match the actual amount ($${actualAmount}).`);
                            return;
                        }

                        // Include property amounts in the form values
                        const finalValues = {
                            ...values,
                            propertyAmounts,
                        };
                        handleFinish(finalValues);
                    }}
                    initialValues={{
                        properties: [],
                        utilityCategory: [],
                    }}
                >
                    <div className="textRow me-2">
                        {!isSplitPayment ? (
                            <Form.Item
                                label="Properties Mode"
                                className="firstFormItem"
                                name="properties"
                                rules={[{ required: true, message: "Please select at least one property." }]}
                            >
                                <Select
                                    className="formControl select-custom mb-3"
                                    style={{ width: "100%" }}
                                    placeholder="Properties Mode"
                                    labelInValue
                                    options={properties}
                                />
                            </Form.Item>
                        ) : (
                            <Row className="splitRow">
                                {properties.map((property) => (
                                    <Col md={11} xs={24} className="splitCol" key={property.value}>
                                        <Form.Item label={property.label} style={{ marginBottom: '16px' }}>
                                            <Input
                                                type="number"
                                                value={propertyAmounts[property.value] || ''}
                                                onChange={(e) => setPropertyAmounts({
                                                    ...propertyAmounts,
                                                    [property.value]: e.target.value,
                                                })}
                                                placeholder={`Amount for ${property.label}`}
                                            />
                                        </Form.Item>
                                    </Col>
                                ))}
                            </Row>
                        )}

                        <div className="splitPaymentButton">
                            <Checkbox onChange={handleSplitPaymentChange}>Split Payment</Checkbox>
                        </div>

                        <Form.Item
                            label="Provider Mode"
                            name="utilityCategory"
                            rules={[{ required: true, message: "Please select at least one utility category." }]}
                        >
                            <Select
                                className="formControl select-custom"
                                style={{ width: "100%" }}
                                placeholder="Provider Mode"
                                labelInValue
                                options={utilityCategory}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Expense Type"
                            name="category_id"
                            rules={[{ required: true, message: "Please select the category." }]}
                        >
                            <Select
                                className="formControl select-custom"
                                style={{ width: "100%" }}
                                placeholder="Expense Type"
                                labelInValue
                                options={category}
                            />
                        </Form.Item>
                    </div>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="authBtn" disabled={isSaving}>
                            {isaddLoading ? 'Loading..' : 'Submit'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    }
    </>


    );

}

export default AllTranscation;
