import React, { useCallback, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useGenrateLinkTokenQuery } from '../slices/getSlice';
import { useAddAccessTokenMutation } from '../slices/apiSlice';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router";
import { Button, Spin, Modal,Table } from 'antd';
import { useSelector } from 'react-redux';

const PlaidLink = () => {
    const { data: generateToken, isLoading, error } = useGenrateLinkTokenQuery();

    if (isLoading) {
        return <Spin size='14' />;
    }

    if (error) {
        return <div>Error generating link token</div>;
    }

    return generateToken?.token?.link_token != null ? (
        <LinkAccount linkToken={generateToken?.token?.link_token} generatedToken={generateToken} />
    ) : (
        <div>No link token available</div>
    );
};

const LinkAccount = ({ linkToken, generatedToken }) => {
    const navigate = useNavigate();
    const [setAccessToken] = useAddAccessTokenMutation();
    const [modalOpen, setModalOpen] = useState()
    const currency = useSelector((state) => state?.persistedReducer?.currency?.currency_symbol)
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5
    const onSuccess = useCallback(
        async (public_token) => {
            try {
                const response = await setAccessToken({ public_token });

                if (response.error) {
                    toast.error(response.error.data.message);
                } else {
                    toast.success('Account Connected');
                    navigate('/dashboard');
                }
            } catch (error) {
                toast.error('Error connecting account');
            }
        },
        [setAccessToken, navigate]
    );

    const config = {
        token: linkToken,
        onSuccess,
    };

    const { open, ready } = usePlaidLink(config);
    const columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            render: (_, record, index) =>
                ((currentPage - 1) * pageSize + index + 1)

        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Type",
            dataIndex: "type",
        },
        {
            title: "Current Balance",
            dataIndex: "current_balance",
            render: (current_balance) => `${currency}${current_balance}`,
        },
        {
            title: "Available Balance",
            dataIndex: "available_balance",
            render: (available_balance) => `${currency}${available_balance}`,
        },
    
    ];

    return (
        <>
            <Button
                type="primary"
                className="btnOutlined"
                onClick={() => {
                    if (!generatedToken?.accounts_data?.length) {
                        open()
                    } else {
                        setModalOpen(true)
                    }
                }}
                disabled={!ready}
            >
                {generatedToken?.accounts_data?.length ? 'Linked Account' : 'Link Account'}
            </Button>
            <Modal
                title={
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>Linked Account</span>
                        <Button type="primary" className="btnOutlined" onClick={() => {open();  setModalOpen(false)}}>
                            Link More Account
                        </Button>
                    </div>
                }
                open={modalOpen}
                onCancel={() => setModalOpen(false)}
                className="assign_model"
                width={720}
                footer={false}
            >
                <div className="modalBody">
                <Table
                                        rowKey={record => record.id}
                                        rowClassName={() => "editable-row"}
                                        bordered
                                        dataSource={generatedToken?.accounts_data}
                                        columns={columns}
                                      
                                        scroll={{ x: '100%' }}
                                        pagination={{
                                            pageSize,
                                            current: currentPage,
                                            onChange: page => setCurrentPage(page),
                                        }}
                                    />
                </div>
            </Modal>
            

        </>

    );
};

export default PlaidLink;
