import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import images from "../../constant/images";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import { logout, resetUserState, saveCurrency } from "../../slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useGetUserDetailQuery } from "../../slices/getSlice";
const Sidebar = ({ loading }) => {
    const location = useLocation();
    const {
        data: getUserDetail,
        isLoading: loadingData,
        refetch,
    } = useGetUserDetailQuery();
    const [selectedKey, setSelectedKey] = useState();
    const [openKeys, setOpenKeys] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Logo } = images;
    const status = useSelector((state) => state.persistedReducer.status);
    const userData = useSelector((state) => state.persistedReducer);
    const parent_id = userData?.user?.user?.parent_id;
    const [menuLabels, setMenuLabels] = useState({});

    const handleLogout = () => {
        dispatch(logout());
        dispatch(resetUserState());
        localStorage.removeItem("dataFetched");
        localStorage.clear();
        navigate("/login");
        toast.success("Logout successfully");
    };

    useEffect(() => {
        setSelectedKey(location.pathname);
    }, [location]);


    useEffect(() => {
        const path = location.pathname;
        let key = path;

        if (
            path === "/view_property" ||
            path === "/add_property" ||
            path === "/property_details"
        ) {
            key = "/properties";
        } else if (path === "/add_income") {
            key = "/income";
        }

        setSelectedKey(key);
    }, [location?.pathname, getUserDetail]);

    useEffect(() => {
        let mydata = getUserDetail?.data?.setting?.custom_data;
        if (typeof mydata != "undefined") {
            setMenuLabels(mydata?.menu);
        }
        dispatch(saveCurrency(getUserDetail?.data?.setting))
    }, [getUserDetail]);

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };

    let SecondaryColor =
        getUserDetail?.data?.setting?.custom_data?.colors?.secondary;

    const handleOpenChange = (keys) => {
        setOpenKeys(keys);
    };

    const handleClick = ({ key, keyPath }) => {
        const isParentMenu = keyPath.length === 1;

        setSelectedKey(key);

        if (isParentMenu) {
            setOpenKeys([]);
        }

        navigate(key);
    };

    const items = [
        {
            key: "/dashboard",
            icon: <i className="fa fa-house menu-icon"></i>,
            disabled: !status,
            path: "/dashboard",
            label: status ? (
                <Link className="opacity" to={"/dashboard"}>
                    {capitalizeFirstLetter(menuLabels?.dashboard) || "Dashboard"}
                </Link>
            ) : (
                <span>
                    {capitalizeFirstLetter(menuLabels?.dashboard) || "Dashboard"}
                </span>
            ),
            className: "custom-menu-item",
        },
        {
            key: "property",
            disabled: !status,
            icon:<i className="fa-solid fa-building menu-icon"></i>,
            label: "Properties",
            className: "custom-sub-menu",
            children: [
                {
                    key: "/properties",
                    icon: <i className="fa-solid fa-building menu-icon"></i>,
                    disabled: !status,
                    label: status ? (
                        <Link to={"/properties"}>
                            {capitalizeFirstLetter(menuLabels?.properties) || "Properties"}
                        </Link>
                    ) : (
                        <span>
                            {capitalizeFirstLetter(menuLabels?.properties) || "Properties"}
                        </span>
                    ),
                },
                {
                    key: "/manage_property",
                    icon: <i className="fa-solid fa-layer-group menu-icon"></i>,
                    hidden: parent_id ? true : false,
                    disabled: !status,
                    label: status ? (
                        <Link to={"/manage_property"}>
                            {capitalizeFirstLetter(menuLabels?.groups) || "Groups"}
                        </Link>
                    ) : (
                        <span>{capitalizeFirstLetter(menuLabels?.groups) || "Groups"}</span>
                    ),
                },
                {
                    key: "/expense",
                    icon: <i className="fa-solid fa-dollar-sign menu-icon"></i>,
                    disabled: !status,
                    label: status ? (
                        <Link to={"/expense"}>
                            {capitalizeFirstLetter(menuLabels?.expense) || "Expense"}
                        </Link>
                    ) : (
                        <span>{capitalizeFirstLetter(menuLabels?.income) || "Expense"}</span>
                    ),
                },
                {
                    key: "/income",
                    icon: <i className="fa-solid fa-hand-holding-dollar menu-icon"></i>,
                    disabled: !status,
                    label: status ? (
                        <Link to={"/income"}>
                            {capitalizeFirstLetter(menuLabels?.income) || "Income"}
                        </Link>
                    ) : (
                        <span>{capitalizeFirstLetter(menuLabels?.income) || "Income"}</span>
                    ),
                },
            ],
        },
            
        {
            key: "analysis",
            disabled: !status,
            icon: <i className="fa-solid fa-chart-simple menu-icon"></i>,
            label: "Analysis",
            className: "custom-sub-menu",
            children: [
                {
                    key: "/market-analysis",
                    label: status ? (
                        <NavLink to={"/market-analysis"}>
                            {capitalizeFirstLetter(menuLabels?.market_analysis) ||
                                "Market Analysis"}
                        </NavLink>
                    ) : (
                        <span>
                            {capitalizeFirstLetter(menuLabels?.market_analysis) ||
                                "Market Analysis"}
                        </span>
                    ),
                    hidden: parent_id ? true : false,
                    disabled: !status,
                    icon: <i className="fa-solid fa-chart-pie menu-icon"></i>,
                },
                {
                    key: "/report-analysis",
                    label: status ? (
                        <NavLink to={"/report-analysis"}>
                            {capitalizeFirstLetter(menuLabels?.report_analysis) ||
                                "Report Analysis"}
                        </NavLink>
                    ) : (
                        <span>
                            {capitalizeFirstLetter(menuLabels?.report_analysis) ||
                                "Report Analysis"}
                        </span>
                    ),
                    disabled: !status,
                    hidden: parent_id ? true : false,
                    icon: <i className="fa-solid fa-file-lines menu-icon"></i>,
                },
                {
                    key: "/report",
                    icon: <i className="fa-solid fa-chart-simple menu-icon"></i>,
                    hidden: parent_id ? true : false,
                    disabled: !status,
                    label: status ? (
                        <Link to={"/report"}>
                            {capitalizeFirstLetter(menuLabels?.overall_performance) ||
                                "Overall Performance"}
                        </Link>
                    ) : (
                        <span>
                            {capitalizeFirstLetter(menuLabels?.overall_performance) ||
                                "Overall Performance"}
                        </span>
                    ),
                },
            ],
        },
        {
            key: "transactions",
            disabled: !status,
            icon: <i className="fa-solid fa-money-bill-transfer menu-icon"></i>,
            label: "All Transactions",
            className: "custom-sub-menu",
            children: [
                {
                    key: "/all-transcation",
                    label: status ? (
                        <NavLink to={"/all-transcation"}>
                            {capitalizeFirstLetter(menuLabels?.transaction) ||
                                "All Transactions"}
                        </NavLink>
                    ) : (
                        <span>
                            {capitalizeFirstLetter(menuLabels?.transaction) ||
                                "All Transactions"}
                        </span>
                    ),
                    disabled: !status,
                    icon: <i className="fa-solid fa-money-bill-transfer menu-icon"></i>,
                },
                {
                    key: "/recurring_transcation",
                    label: status ? (
                        <NavLink to={"/recurring_transcation"}>
                            {capitalizeFirstLetter(menuLabels?.recurring_transaction) ||
                                "Recurring Transaction"}
                        </NavLink>
                    ) : (
                        <span>
                            {capitalizeFirstLetter(menuLabels?.recurring_transaction) ||
                                "Recurring Transaction"}
                        </span>
                    ),
                    disabled: !status,
                    icon: <i className="fa-solid fa-arrow-right-arrow-left menu-icon"></i>,
                },
            ],
        },
        {
            key: "settings",
            disabled: !status,
            icon: <i className="fa-solid fa-gear menu-icon"></i>,
            label: "Settings",
            className: "custom-sub-menu",
            children: [
                {
                    key: "/sub_account",
                    icon: <i className="fa-solid fa-users menu-icon"></i>,
                    hidden: parent_id ? true : false,
                    disabled: !status,
                    label: status ? (
                        <Link to={"/sub_account"}>
                            {capitalizeFirstLetter(menuLabels?.sub_account) || "Sub Accounts"}
                        </Link>
                    ) : (
                        <span>
                            {capitalizeFirstLetter(menuLabels?.sub_account) || "Sub Accounts"}
                        </span>
                    ),
                    
                },
                {
                    key: "/setting",
                    icon: <i className="fa-solid fa-gear menu-icon"></i>,
                    label: status ? (
                        <Link to={"/setting"}>
                            {capitalizeFirstLetter(menuLabels?.setting) || "Setting"}
                        </Link>
                    ) : (
                        <span>{capitalizeFirstLetter(menuLabels?.setting) || "Setting"}</span>
                    ),
                    hidden: parent_id ? true : false,
                    disabled: !status,
                },
            ],
        },
      
        {
            key: 9,
            icon: <i className="fa-solid fa-right-from-bracket menu-icon"></i>,
            label: "Logout",
            style: { marginTop: "44px" },
            className: "custom-menu-item bottem-menu-item",
            onClick: handleLogout,
        },
    ];

    return loading ? (
        <></>
    ) : (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            style={{
                background:
                    loadingData && SecondaryColor === undefined
                        ? "#fff"
                        : SecondaryColor || "#3A54A5",
                height: "100vh",
                position: "fixed",
            }}
            width="300"
            className="mobWidth"
        >
            <div className="logoContainer">
                <Logo
                    onClick={() => {
                        navigate("/dashboard");
                    }}
                />
            </div>
            {
                <Menu
                    mode="inline"
                    style={{ background: SecondaryColor || "#3A54A5" }}
                    items={items}
                    selectedKeys={[selectedKey]}
                    openKeys={openKeys}
                    onOpenChange={handleOpenChange}
                    onClick={handleClick}
                />
            }
        </Sider>
    );
};
export default Sidebar;
