import React, { useEffect, useState } from "react";
import Title from "../../component/PageTitle";
import { Row, Col, Slider, Input, Button, Spin } from "antd";
import { PiMapPinSimpleAreaLight } from "react-icons/pi";
import { RiRefreshLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import * as expenseService from "../../services/expensesServices";
import { LoadingOutlined } from '@ant-design/icons';
import { useOutletContext } from "react-router-dom";

export default function MarketAnalysis() {
  const dispatch = useDispatch();
  const { loading, setLoading } = useOutletContext();
  const [address, setAddress] = useState(null);
  const [rangePicker, setRangePicker] = useState(10);
  const [marketAnalysis, setMarketAnalysis] = useState();
  const [loader, setLoader] = useState(false);
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleRangePicker = (e) => {

    setRangePicker(e);
  };

  const handleAddressRange = () => {
    setLoader(true);
    let formData;
    formData = {
      address: address,
      range: rangePicker,
    };

    dispatch(expenseService.marketAnalysis(formData))
      .then((response) => {
        setMarketAnalysis(response);
        setLoader(false);
       
      })
      .catch((error) => {
        setLoader(false);
        console.error({ error });
      });
  };
  useEffect(()=>{
setLoading(false)
  },[])


  return (
    <>
    {loading?
      <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(255, 255, 255, 1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin size="large" />
    </div>:
    <>
      <div className="mainWrapper">
        <div className="card titleCard mb-4">
          <div className="card-body d-flex justify-content-between align-items-center">
            <Title title="Market Analysis" />
          </div>
        </div>
      </div>
      <div className="address-range-container">
        <Row className="address-range-row">
          <Col md={16} className="address-col">
            <div className="address-container">
              <p className="address-text">Address</p>
              <Input
                prefix={<PiMapPinSimpleAreaLight className="pin-icon" />}
                className="address-input"
                placeholder="Enter the address"
                onChange={handleAddressChange}
              />
            </div>
          </Col>
          <Col md={8} className="range-slider-col">
            <p className="set-range-text">Set Range</p>
            <Slider
              onChange={handleRangePicker}
              min={1}
              max={25}
              className="slider-input"
              defaultValue={10}
            />
            <div className="kilometer-text-container">
              <p className="min-kilometer">1km</p>
              <p className="max-kilometer">25km</p>
            </div>
          </Col>
          <Col md={24} className="note-container">
            <p className="note-text">
              <span className="note-span">Note</span>:- You can include the
              address within the specified range.
            </p>
          </Col>
        </Row>
        <div className="view-market-analysis-container">
          <button
            onClick={handleAddressRange}
            className="view-market-analysis-button btn btnFilled"
          >
            {loader ? (
              <>
                <Spin indicator={<LoadingOutlined spin />} size="large" /> &nbsp;Processing Data
              </>
            ) : (
              <>
                View Market Analysis
                <span className="refresh-span">
                  <RiRefreshLine className="refresh-icon" />
                </span>
              </>
            )}
          </button>
        </div>
        {marketAnalysis !== undefined ? (
          <>
            <div className="data-fetched-container">
              <p className="data-fetched-text">Data Fetched by Modal</p>
              <div className="data-fetched-result">
                <div className="market-analysis-container">
                  <p>Market Analysis of {marketAnalysis?.address}</p>
                </div>
                <div className="market-condition-container">
                  <p>Market Condition</p>
                  <p>
                    {marketAnalysis?.market_conditions?.employment_market?.description}
                  </p>
                </div>
                <div className="real-estate-condition-container">
                  <p>Commercial Market</p>
                  <p>
                    {marketAnalysis?.market_conditions?.real_estate_market?.commercial?.description}
                  </p>
                  <p>Residential Market</p>
                  <p>
                    {marketAnalysis?.market_conditions?.real_estate_market?.residential?.description}
                  </p>

                  <p>Retail Market</p>
                  <p>{marketAnalysis?.market_conditions?.retail_market?.description}</p>
                </div>
              </div>
            </div>
          </>
        ) : ("")}

      </div>
    </>
      }
      </>
  );
}
